import Amplify from "aws-amplify"
import { Box, Grid, Layer, Text, ResponsiveContext } from "grommet"
import React, { useState, useContext, useEffect } from "react"
import awsconfig from "../aws-exports"
import Button from "../components/button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WindowPanel from "../components/windowPanel"
import materials3d from "../images/materials3d_img@2x.png"
import materials_img from "../images/materials_img@2x.png"
import mediaInfo from "../images/media_img@2x.png"
import { Link } from "gatsby"
import { logEvent } from "../helpers/events"
import IconDownload from "../images/ic_download.inline.svg"
import IconFile from "../images/ic_file.inline.svg"
import { useDispatch } from "react-redux"
import { getProgress, clearProgress } from "../helpers/events"

Amplify.configure(awsconfig)

const background = "black"

const mediaLinks = {
  media: {
    title: "MEDIA INFORMATION",
    zip:
      "https://adidas4fwd.s3-eu-west-1.amazonaws.com/media_information/MEDIA_INFORMATION.zip",
    files: [
      {
        name: "4DFWD_ATHLETE_QUOTES.pdf",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/media_information/4DFWD_ATHLETE_QUOTES.pdf",
      },
      {
        name: "4DFWD_PRESS_RELEASE_FINAL.docx",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/media_information/4DFWD_PRESS_RELEASE_FINAL.docx",
      },
      {
        name: "ADIDAS_4DFWD_TECHSHEET_1.pdf",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/media_information/ADIDAS_4DFWD_TECHSHEET_1.pdf",
      },
      {
        name: "ADIDAS_4DFWD_TECHSHEET_2.pdf",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/media_information/ADIDAS_4DFWD_TECHSHEET_2.pdf",
      },
    ],
  },

  "2d": {
    title: "2D MATERIALS",
    zip:
      "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/2D_MATERIALS.zip",
    files: [
      {
        name: "ADIDAS_SPOKESPERSON.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/ADIDAS_SPOKESPERSON.zip",
      },
      {
        name: "ATHLETE_PHOTOGRAPHY.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/ATHLETE_PHOTOGRAPHY.zip",
      },
      {
        name: "CARBON.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/CARBON.zip",
      },
      {
        name: "LAUNCH_EVENT_BTS.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/LAUNCH_EVENT_BTS.zip",
      },
      {
        name: "PRODUCT_IMAGERY_MAY_LAUNCH.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/2d_materials/PRODUCT_IMAGERY_MAY_LAUNCH.zip",
      },
    ],
  },
  "3d": {
    title: "3D MATERIALS",
    zip:
      "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/3D_MATERIALS.zip",
    files: [
      {
        name: "ADIDAS_4DFWD_360_SHOE.zip",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_360_SHOE.zip",
      },
      {
        name: "ADIDAS_4DFWD_BENEFIT_ANIMATION.ZIP",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_BENEFIT_ANIMATION.zip",
      },
      {
        name: "ADIDAS_4DFWD_FWD_CELL.zip",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_FWD_CELL.zip",
      },
      {
        name: "ADIDAS_4DFWD_INNOVATION_FILM.mp4",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_INNOVATION_FILM.mp4",
      },
      {
        name: "ADIDAS_4DFWD_MIDSOLE_ANIMATION.mp4",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_MIDSOLE_ANIMATION.mp4",
      },
      {
        name: "ADIDAS_4DFWD_SHEARING_MOTION.zip",
        src:
          "https://adidas4fwd.s3-eu-west-1.amazonaws.com/3d_materials/ADIDAS_4DFWD_SHEARING_MOTION.zip",
      },
    ],
  },
}

const Gui = () => {
  // const progress = useSelector(state => state.progress)

  const theme = {
    stroke: "#999999",
    fill: "black",
    text: "white",
    logo: "white",
    fwdlogo: "white",
    logoOnly: true,
  }

  return (
    <Box style={{ position: "fixed", top: 0, zIndex: 99999, width: "100%" }}>
     
    </Box>
  )
}

const ThankyouComponent = () => {
  const [show, setShow] = React.useState()
  const size = useContext(ResponsiveContext)
  const [mobile, setMobile] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setMobile(size === "xxsmall" || size === "xsmall")
  }, [size])

  useEffect(() => {
    dispatch({
      type: "clearProgress",
    })
    clearProgress()
  }, [])

  return (
    <>
      <Box
        style={{ zIndex: 5, position: "relative" }}
        fill={mobile ? false : true}
        flex={"grow"}
        pad={{ top: "150px", bottom: "50px", horizontal: "50px" }}
        direction={mobile ? "column" : "row"}
        gap={"medium"}
      >
        <Box flex={"grow"} basis={mobile ? "auto" : "1/3"}>
          <h2 className="white">{`THANK YOU\nFOR WATCHING`}</h2>
          <p className="body white">
            Please note that content should NOT be posted across your personal
            or publication’s social media channels before the PR embargo date of
            9am CET on Wednesday 5th May 2021.
          </p>
        </Box>
        <Box flex="grow" basis={mobile ? "full" : "2/3"}>
          <Grid
            pad={mobile ? "none" : "5px"}
            fill={true}
            rows={mobile ? "medium" : ["1/3", "1/3", "1/3"]}
            columns={mobile ? "100%" : ["1/2", "1/2"]}
          >
            <Box
              pad="20px"
              margin={
                mobile ? { vertical: "25px", horizontal: "none" } : "10px"
              }
              background="white"
              justify={"between"}
            >
              <h2 style={{ margin: 0, fontSize: "5.5vh", color: "black" }}>
                WATCH AGAIN
              </h2>
              <a
                href={
                  mobile ? "/app/main/?override=true" : "/app/main/?section=2"
                }
                onClick={() => {
                  //logEvent("watchAgain", { section: "all" })
                }}
              >
                <Button
                  background={"black"}
                  height={35}
                  width={"100%"}
                  fontSize={12}
                  text={"START NOW"}
                />
              </a>
            </Box>
            <Box
              margin={
                mobile ? { vertical: "25px", horizontal: "none" } : "10px"
              }
              background="white"
            >
              <Box
                height="20px"
                justify="center"
                pad={{ horizontal: "5px" }}
                flex={false}
              >
                <Text className="title-bar">MEDIA INFORMATION</Text>
              </Box>
              <Box
                flex={true}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  backgroundImage: `url(${mediaInfo})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Box
                  direction={"row"}
                  style={{ position: "absolute", bottom: 15, right: 15 }}
                >
                  <Button
                    onClick={() => setShow("media")}
                    background={"black"}
                    height={35}
                    width={"110px"}
                    fontSize={12}
                    text={"OPEN"}
                  />
                  <a
                    href={mediaLinks.media.zip}
                    onClick={() => {
                      //logEvent("downloadZip", { asset: "Media Info" })
                    }}
                  >
                    <Button
                      style={{ marginLeft: 15 }}
                      background={"black"}
                      height={35}
                      width={"65px"}
                      fontSize={12}
                      icon={<IconDownload />}
                    />
                  </a>
                </Box>
              </Box>
            </Box>
            {!mobile && (
              <Box
                pad="20px"
                margin={
                  mobile ? { vertical: "25px", horizontal: "none" } : "10px"
                }
                background="white"
              >
                <h2 style={{ margin: 0, fontSize: "5.5vh", color: "black" }}>
                  REPLAY A PART
                </h2>
                <Box flex="grow"></Box>
                <Grid
                  rows={["auto", "auto"]}
                  columns={["auto", "auto"]}
                  gap="10px"
                >
                  <a
                    href="/app/main/?section=2"
                    onClick={() => {
                      //logEvent("watchAgain", { section: "01" })
                    }}
                  >
                    <Button
                      background={"black"}
                      height={35}
                      fontSize={12}
                      width={"100%"}
                      text={"PART 01"}
                    />
                  </a>
                  <a
                    href="/app/main/?section=3"
                    onClick={() => {
                      //logEvent("watchAgain", { section: "02" })
                    }}
                  >
                    <Button
                      background={"black"}
                      height={35}
                      fontSize={12}
                      width={"100%"}
                      text={"PART 02"}
                    />
                  </a>
                  <a
                    href="/app/main/?section=4"
                    onClick={() => {
                      //logEvent("watchAgain", { section: "03" })
                    }}
                  >
                    <Button
                      background={"black"}
                      height={35}
                      fontSize={12}
                      width={"100%"}
                      text={"PART 03"}
                    />
                  </a>
                  <a
                    href="/app/main/?section=5"
                    onClick={() => {
                      //logEvent("watchAgain", { section: "04" })
                    }}
                  >
                    <Button
                      background={"black"}
                      height={35}
                      fontSize={12}
                      width={"100%"}
                      text={"PART 04"}
                    />
                  </a>
                </Grid>
              </Box>
            )}

            <Box
              margin={
                mobile ? { vertical: "25px", horizontal: "none" } : "10px"
              }
              background="white"
              direction="column"
            >
              <Box
                height="20px"
                justify="center"
                pad={{ horizontal: "5px" }}
                flex={false}
              >
                <Text className="title-bar">2D MATERIALS</Text>
              </Box>
              <Box
                flex={true}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  backgroundImage: `url(${materials_img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Box
                  direction={"row"}
                  style={{ position: "absolute", bottom: 15, right: 15 }}
                >
                  <Button
                    onClick={() => setShow("2d")}
                    background={"black"}
                    height={35}
                    width={"110px"}
                    fontSize={12}
                    text={"OPEN"}
                  />
                  <a
                    href={mediaLinks["2d"].zip}
                    onClick={() => {
                      //logEvent("downloadZip", { asset: "2D Materials" })
                    }}
                  >
                    <Button
                      style={{ marginLeft: 15 }}
                      background={"black"}
                      height={35}
                      width={"65px"}
                      fontSize={12}
                      icon={<IconDownload />}
                    />
                  </a>
                </Box>
              </Box>
            </Box>
            <Box
              pad="20px"
              margin={
                mobile ? { vertical: "25px", horizontal: "none" } : "10px"
              }
              background="white"
            >
              <h2 style={{ margin: 0, fontSize: "5.5vh", color: "black" }}>
                NEWSROOM
              </h2>
              <Box flex="grow"></Box>
              <a
                href="https://news.adidas.com/4d"
                target="_blank"
                onClick={() => {
                  //logEvent("newsRoom")
                }}
              >
                <Button
                  background={"black"}
                  height={35}
                  width={"100%"}
                  fontSize={12}
                  text={"Visit Newsroom"}
                />
              </a>
            </Box>

            <Box
              margin={
                mobile ? { vertical: "25px", horizontal: "none" } : "10px"
              }
              background="white"
            >
              <Box
                height="20px"
                justify="center"
                pad={{ horizontal: "5px" }}
                flex={false}
              >
                <Text className="title-bar">3D MATERIALS</Text>
              </Box>
              <Box
                flex={true}
                style={{
                  position: "relative",
                  overflow: "hidden",
                  backgroundImage: `url(${materials3d})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
              >
                <Box
                  direction={"row"}
                  style={{ position: "absolute", bottom: 15, right: 15 }}
                >
                  <Button
                    onClick={() => setShow("3d")}
                    background={"black"}
                    height={35}
                    width={"110px"}
                    fontSize={12}
                    text={"OPEN"}
                  />
                  <a
                    href={mediaLinks["3d"].zip}
                    onClick={() => {
                      //logEvent("downloadZip", { asset: "3D Materials" })
                    }}
                  >
                    <Button
                      style={{ marginLeft: 15 }}
                      background={"black"}
                      height={35}
                      width={"65px"}
                      fontSize={12}
                      icon={<IconDownload />}
                    />
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>

      {show && (
        <Layer
          style={{ zIndex: 99999 }}
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <WindowPanel
            width={"590px"}
            color={"white"}
            title={mediaLinks[show].title}
            close={true}
            closeAction={() => setShow(false)}
          >
            {mediaLinks[show] &&
              mediaLinks[show].files.map(mediaRow => (
                <MediaRow content={mediaRow} />
              ))}
            <a href={mediaLinks[show].zip}>
              <Button
                style={{ marginTop: 20 }}
                background={"black"}
                height={35}
                width={"100%"}
                fontSize={12}
                text={"Download All"}
                icon={<IconDownload />}
              />
            </a>
          </WindowPanel>
        </Layer>
      )}
    </>
  )
}

const Thankyou = () => {
  return (
    <Layout background={background}>
      <SEO background={background} title="Thankyou" />

    

      <ThankyouComponent />

      <Gui />
    </Layout>
  )
}

const MediaRow = ({ content }) => {
  return (
    <Box
      direction="row"
      height="60px"
      align="center"
      border={{ side: "bottom", width: "0.5px", color: "black" }}
    >
      <Box width="7%" align="start">
        <IconFile />
      </Box>
      <Box flex="grow">
        <Text style={{ fontSize: 14, fontFamily: "AdihausDIN" }}>
          {content.name}
        </Text>
      </Box>
      <Box align="end" width="62px">
        <a
          href={content.src}
          onClick={() => {
            //logEvent("downloadAsset", { asset: content.name })
          }}
        >
          <Button
            background={"black"}
            height={35}
            width={"100%"}
            fontSize={12}
            icon={<IconDownload />}
          />
        </a>
      </Box>
    </Box>
  )
}
export default Thankyou
