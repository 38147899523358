import { Router } from "@reach/router";
import React from "react";
import PrivateRoute from "../components/privateRoute";
import PublicRoute from "../components/publicRoute";
import Login from "../screens/login";
// import Main from "../screens/main"
import Thanks from "../screens/thanks";
import VideoScreen from "../screens/Video";
import Loader from "../screens/loader";

// import Curious from "../screens/curious";

function RouterComponent({ children }) {
  // Shorthand of <React.Fragment>{children}</React.Fragment>
  return <>{children}</>;
}

const App = ({ location }) => (
  <Router primary={false} component={RouterComponent}>
    <PublicRoute path="/app/video" component={VideoScreen} />
    <PublicRoute path="/app/loader" component={Loader} />

    {/* <PublicRoute path="/app/curious" component={Curious} /> */}
    <PrivateRoute path="/app/end" component={Thanks} />
    <Login path="/app/login" />
  </Router>
);
export default App;
